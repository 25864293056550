import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Button } from 'react-bootstrap';

import '../generic-page.scss';
import './play-on-pc.scss';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const EversoulPlayonPCPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page play-on-pc-page'} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul/">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>Play on PC</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/eversoul/categories/category_about.jpg"
            alt="About"
          />
        </div>
        <div className="page-details">
          <h1>Play Eversoul on PC with LDPlayer</h1>
        </div>
      </div>
      <div className="page-content">
        <div className="banner play-on-pc eversoul">
          <div className="cta">
            <h4>Play Eversoul on PC</h4>
            <OutboundLink
              href="https://en.ldplayer.net/games/eversoul-on-pc.html?n=82774712#utm_source=aff&utm_medium=aff&utm_campaign=aff82774712"
              target="_blank"
            >
              <Button variant="primary">Play now on LDPlayer</Button>
            </OutboundLink>
          </div>
        </div>
        <SectionHeader title="Play on PC" />
        <p>
          Eversoul is a idle RPG game from Kakao Games, and you can enjoy
          beautiful visual RPG gameplay here with so many amazing souls. You
          have to collect them and make them enjoy the gameplay with Eversoul by
          choosing your own fate. The best emulator: LDPlayer 9 comes as the
          best platform with best features to enjoy Eversoul on a better scale
          than ever.
        </p>
        <p>
          With LDPlayer, you can both play Eversoul on your big screen monitor
          with better graphics, and enhance your gaming experience with the
          follow features (make sure to create a 64bit instance!).
        </p>
        <SectionHeader title="Benefits of using LDPlayer" />
        <ul>
          <li>
            You can play the game for a long time without worrying about the
            battery and heating issues of your mobile device.
          </li>
          <li>
            You can write macros to help your gaming progress. Let the emulator
            do the hard work while you AFK and relax!
          </li>
          <li>
            You can use macros to trigger combos or accurate clicks, which will
            help you control the gameplay of Eversoul much easier.
          </li>
          <li>
            You can play multiple accounts in one computer with our
            multi-instance and multi-instance sync features.
          </li>
        </ul>
        <SectionHeader title="Is it legal and safe to use LDPlayer?" />
        <p>
          LDPlayer emulator is completely legal and safe. Without the player’s
          permission, LDPlayer will never install any software on your computer
          or violate the player’s privacy. In addition, we do not in any way
          install malware or miners to mine cryptocurrency.
        </p>
        <SectionHeader title="Recommended PC configuration" />
        <p>
          The following specifications are recommended for playing one or two
          instances:
        </p>
        <ul>
          <li>CPU: Intel or AMD Processor like x86 or x86_64</li>
          <li>
            System: Win7 / Win8 / Win8.1 / Win10 / Win11 (Do not support Mac
            version)
          </li>
          <li>
            Graphics Driver: Windows DirectX 11 or Graphics driver with OpenGL
            2.0
          </li>
          <li>RAM: 16GB</li>
          <li>Disk Space: Minimum 100GB</li>
          <li>
            VT: Virtualization Technology (Intel VT-x/AMD-V) enabled.{' '}
            <a
              href="https://www.ldplayer.net/blog/how-to-enable-vt.html"
              target="_blank"
              rel="noreferrer"
            >
              Simple Steps to enable VT
            </a>
            .
          </li>
        </ul>
        <p>
          If you have needs for playing multiple instances, it's recommended to
          use the CPU with higher numbers of cores and graphics card with higher
          vram. You can refer to this tutorial{' '}
          <a
            href="https://www.ldplayer.net/blog/how-to-open-as-many-instances-of-LDPlayer-as-possible.html"
            target="_blank"
            rel="noreferrer"
          >
            How to open as many instances of LDPlayer as possible
          </a>
          .
        </p>
        <SectionHeader title="Play Eversoul and support Prydwen" />
        <p>
          If you download LDPlayer and play Eversoul (or other games) on it,
          using our promotional link, you will support the website. Thank you!
        </p>
        <OutboundLink
          href="https://en.ldplayer.net/games/eversoul-on-pc.html?n=82774712#utm_source=aff&utm_medium=aff&utm_campaign=aff82774712"
          target="_blank"
        >
          <Button variant="primary">Download LDPlayer</Button>
        </OutboundLink>
        <br /> <br />
        <h6>Download Eversoul APK</h6>
        <p>
          If the game isn't available in your country or you want to install it
          on LDPlayer without the need to log in to Google Store, download the
          APK from the link below:
        </p>
        <OutboundLink
          href="https://apkpure.com/eversoul/com.kakaogames.eversoul"
          target="_blank"
        >
          <Button variant="primary">Download APK</Button>
        </OutboundLink>
      </div>
    </DashboardLayout>
  );
};

export default EversoulPlayonPCPage;

export const Head: React.FC = () => (
  <Seo
    title="Play on PC | Eversoul | Prydwen Institute"
    description="Play Eversoul gacha game on your PC."
    game="eversoul"
  />
);
